import { GetServerSideProps } from 'next';
import axios from 'axios';

import { defaultVenueSubDomain } from '../api/constants';
import { EventNames, LayoutMode } from '../common/constants/constants';
import {
  VenueProperties,
  VenuePropsDTO,
  VenueAPIModel,
  OrganizationAPIModel,
  WebappCard,
  HomePageProps,
} from '../api/models/venue';

import { HeaderProps } from '../../common/header';
import { httpClient } from '../services/httpClient/httpClient';
import { httpClient as serverSidehttpClient } from '../services/serversideHttpClient/serversideHttpClient';
import { getApiUrlForId, AccountEndpoints, UploadEndpoints } from '../api/endpoints';
import { AppRoutes } from '../common/constants/routes';
import { CloudinaryURL, getCloudinaryFrames, getVideoURLWithAspectRatio } from '../pages/VideoToolPage/utils';
import { Frame } from '../common/components/UploadedVideoPreview/SelectThumbnailModal/SelectThumbnailModal';
import { IEventTracker } from '../common/hooks/useTrackEvent';
import getBlobDuration from 'get-blob-duration';
import { UserSource } from '../api/models/auth';
import { CampaignTypes } from '../api/models/rewards';

export const downloadMedia = (path: string) => {
  const a = document.createElement('a');
  a.setAttribute('href', path);
  a.setAttribute('download', 'test.mp4');
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const emailMaskRegex = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$');

export const getSubdomain = (route?: string) => {
  if (route) {
    return route.split('.')[1] ? route.split('.')[0] : defaultVenueSubDomain;
  }
  if (typeof window !== 'undefined') {
    return window.location.host.split('.')[1] ? window.location.host.split('.')[0] : defaultVenueSubDomain;
  }
  return '';
};

export async function copyToClipboard(text: string): Promise<void> {
  return navigator.clipboard.writeText(text);
}

export function getFilterPublicId(url: string) {
  if (url && url.includes('sv-dev-assets/')) {
    let [, path] = url.split('sv-dev-assets/');
    let withoutExt = path.replace('.png', '');
    let rightPath = withoutExt.replace('/', ':');
    return `l_sv-dev-assets:${rightPath}`;
  }

  if (url && url.includes('sv-prod-assets/')) {
    let [, path] = url.split('sv-prod-assets/');
    let withoutExt = path.replace('.png', '');
    let rightPath = withoutExt.replace('/', ':');
    return `l_sv-prod-assets:${rightPath}`;
  }

  return '';
}

export const getVideoThumbnail = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    if (file.type.match('image')) {
      fileReader.onload = function () {
        if (fileReader.result && typeof fileReader.result === 'string') {
          resolve(fileReader.result);
        }
      };
      fileReader.readAsDataURL(file);
    } else {
      fileReader.onload = function () {
        if (!fileReader.result) return;

        const blob = new Blob([fileReader.result], { type: file.type });
        const url = URL.createObjectURL(blob);
        const video = document.createElement('video');
        const timeupdate = function () {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate);
            video.pause();
          }
        };
        video.addEventListener('loadeddata', function () {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate);
          }
        });
        const snapImage = () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext('2d');
          if (!ctx) return;
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          const image = canvas.toDataURL();
          const minImageLength = 100000;
          const success = image.length > minImageLength;
          if (success) {
            URL.revokeObjectURL(url);
            resolve(image);
          }
          return success;
        };
        video.addEventListener('timeupdate', timeupdate);
        video.preload = 'metadata';
        video.src = url;
        // Load video in Safari / IE11
        video.muted = true;
        // TODO: update our build ts
        // @ts-ignore
        video.playsInline = true;
        video.play();
      };
      fileReader.readAsArrayBuffer(file);
    }
  });
};

export const getLayoutFormat = (screenWidth: number): LayoutMode => {
  if (screenWidth >= 1280) return LayoutMode.Desktop;
  if (screenWidth < 1280 && screenWidth >= 640) return LayoutMode.Tablet;
  if (screenWidth < 640) return LayoutMode.Mobile;
  return LayoutMode.Desktop;
};
const defaultSlesHeaderValues: HeaderProps = {
  metaOGTitle: '',
  metaOGDescription: '',
  metaOGImg: '',
  metaTwitterSiteId: '',
  metaTwitterTitle: '',
  metaTwitterImg: '',
  metaTwitterDescription: '',
  metaOGUrl: '',
  accountId: '',
};

export const getSalesSocialMetaTags = (accountProperties: Partial<VenueProperties>, userName: string): VenuePropsDTO => {
  return {
    metaOGUrl: '',
    metaOGTitle: userName
      ? defaultSlesHeaderValues.metaOGTitle.replace(':name', userName || '')
      : accountProperties['webapp.sharing']?.['meta-og-title'] || '',
    metaOGDescription: defaultSlesHeaderValues.metaOGDescription,
    metaOGImg: defaultSlesHeaderValues.metaOGImg,
    metaOGVideo: '',
    metaTwitterImg: defaultSlesHeaderValues.metaTwitterImg,
    metaTwitterTitle: userName ? defaultSlesHeaderValues.metaTwitterTitle.replace(':name', userName || '') : '',
    metaTwitterSiteId: accountProperties['webapp.sharing']
      ? accountProperties['webapp.sharing']['meta-twitter-site-id']
      : defaultSlesHeaderValues.metaTwitterSiteId,
    metaTwitterDescription: defaultSlesHeaderValues.metaTwitterDescription,
  };
};

const defaultHeaderValues: HeaderProps = {
  metaOGTitle: '',
  metaOGDescription: '',
  metaOGImg: '',
  metaTwitterSiteId: '',
  metaTwitterTitle: '',
  metaTwitterImg: '',
  metaTwitterDescription: '',
  metaOGUrl: '',
  accountId: '',
};

const generalHeaderValues: HeaderProps = {
  metaOGTitle: '',
  metaOGDescription: '',
  metaOGImg: '',
  metaTwitterSiteId: '',
  metaTwitterTitle: '',
  metaTwitterImg: '',
  metaTwitterDescription: '',
  metaOGUrl: '',
  accountId: '',
};

export const getGeneralMetaTags = (name: string, logo: string, subdomain?: string): VenuePropsDTO => {
  return {
    metaOGUrl: subdomain ? `${subdomain}.socialv.io` : '',
    metaOGTitle: name,
    metaOGDescription: generalHeaderValues.metaOGDescription,
    metaOGImg: logo || '',
    metaOGVideo: '',
    metaTwitterImg: logo || '',
    metaTwitterTitle: name,
    metaTwitterSiteId: generalHeaderValues.metaTwitterSiteId,
    metaTwitterDescription: generalHeaderValues.metaTwitterDescription,
  };
};

export const getSocialMetaTags = (accountProperties: Partial<VenueProperties>, accountName: string): VenuePropsDTO => {
  return {
    metaOGUrl: '',
    metaOGTitle: accountName
      ? defaultHeaderValues.metaOGTitle.replace(':programName', accountName || '')
      : accountProperties['webapp.sharing']?.['meta-og-title'] || '',
    metaOGDescription: defaultHeaderValues.metaOGDescription,
    metaOGImg: defaultHeaderValues.metaOGImg,
    metaOGVideo: '',
    metaTwitterImg: defaultHeaderValues.metaTwitterImg,
    metaTwitterTitle: accountName ? defaultHeaderValues.metaTwitterTitle.replace(':programName', accountName || '') : '',
    metaTwitterSiteId: defaultHeaderValues.metaTwitterSiteId,
    metaTwitterDescription: defaultHeaderValues.metaTwitterDescription,
  };
};

export const posterUrl = (videoUrl: string) => {
  if (!videoUrl) {
    return '';
  }

  let arr = videoUrl.split('.');
  arr.pop();
  let url = arr.join('.');
  return url + '.jpg';
};

export const getServerSideHelper: GetServerSideProps = async (context): Promise<{ props: HomePageProps }> => {
  const host = getSubdomain(context.req.headers.host);

  try {
    const accountData = await serverSidehttpClient.get<undefined, VenueAPIModel>({
      url: getApiUrlForId(AccountEndpoints.Account, host),
      requiresToken: false,
    });
    // const accountProperties = await httpClient.get<undefined, VenueProperties>({
    //   url: getApiUrlForId(VenueEndpoints.GetVenueProperties, accountData.id),
    //   requiresToken: false,
    // });
    let organization = {
      name: '',
      logo: '',
    };
    if (accountData.orgId) {
      organization = await serverSidehttpClient.get<undefined, OrganizationAPIModel>({
        url: getApiUrlForId(AccountEndpoints.GetOrganizationById, accountData.orgId),
        requiresToken: false,
      });
    }
    const name = organization.name || accountData.name;
    const logo = organization.logo || accountData.logo;
    return {
      props: {
        venueLogo: accountData.logo,
        ...getGeneralMetaTags(name, logo, accountData.subdomain),
        title: accountData.name,
        accountId: accountData.id,
      },
    };
  } catch (e) {
    return {
      props: {
        locale: 'en',
        metaOGTitle: defaultHeaderValues.metaOGTitle,
        metaOGDescription: defaultHeaderValues.metaOGDescription,
        metaOGImg: defaultHeaderValues.metaOGImg,
        metaTwitterImg: defaultHeaderValues.metaTwitterImg,
        metaTwitterTitle: defaultHeaderValues.metaTwitterTitle,
        metaTwitterSiteId: defaultHeaderValues.metaTwitterSiteId,
        metaTwitterDescription: defaultHeaderValues.metaTwitterDescription,
        accountId: defaultHeaderValues.accountId,
      },
    };
  }
};

export interface UploadUrlApiModel {
  url: string;
  method: string;
  headers: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
  downloadUrl: string;
}

interface UploadFileRequest {
  options: UploadUrlApiModel;
  data: File;
}

export const createSignedUploadStorageUrl = async (mimeType: string, accountId: string) => {
  try {
    return httpClient.post<undefined, UploadUrlApiModel>({
      url: `${UploadEndpoints.CreateAvatarUploadUrl}?ext=${mimeType}&accountId=${accountId}`,
      requiresToken: true,
    });
  } catch (error) {
    return console.log(error);
  }
};

export const uploadFile = async ({ options, data }: UploadFileRequest) => {
  if (!data) {
    return;
  }
  try {
    const res = await axios.put<typeof options, undefined>(options.url, data, {
      headers: options.headers,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getDisplayName = (name: string | null) => (name ? name.split(' ')[0] : '');
export const capitalizeFullName = (name: string | null) => {
  if (!name) {
    return;
  }
  const names: string[] = name.split(' ');

  // Capitalize the first letter of each name
  let capitalizedNames = names.map((name) => name.charAt(0).toUpperCase() + name.slice(1));

  // Join the names back into a string
  return capitalizedNames.join(' ');
};
export const numberWithCommas = (value: number | string = ''): string => {
  if (!value) {
    return '0';
  }

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getUserEmailFromQueryParams = (url: string | undefined): string => {
  if (!url) {
    return '';
  }

  const authPageUrl = `${AppRoutes.Auth}?`;
  const welcomePageUrl = `${AppRoutes.Welcome}?`;
  const queryEmailName = 'email=';
  let userEmail = '';
  const routerQueries = url.replace(authPageUrl, '').replace(welcomePageUrl, '').split('&');

  routerQueries.forEach((query: string) => {
    if (query.includes(queryEmailName)) {
      userEmail = query.replace(queryEmailName, '');
    }
  });

  return userEmail;
};

export const getSVIdentFromQueryParams = (url: string | undefined): string => {
  if (!url) {
    return '';
  }

  const authPageUrl = `${AppRoutes.Auth}?`;
  const welcomePageUrl = `${AppRoutes.Welcome}?`;
  const queryEmailName = 'svIdent=';
  let userEmail = '';
  const routerQueries = url.replace(authPageUrl, '').replace(welcomePageUrl, '').split('&');

  routerQueries.forEach((query: string) => {
    if (query.includes(queryEmailName)) {
      userEmail = query.replace(queryEmailName, '');
    }
  });

  return userEmail;
};

export const Capitalize = (str: string | undefined) => {
  let name = str || '';
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const removeduplicates = (arr: Array<{ id: string; name: string }>) => {
  const response = arr.filter((item, index, self) => {
    return index === self.findIndex((t) => t.id === item.id);
  });
  return [...response];
};

export const getDonationUrl = (url?: string | null) => {
  if (!url) {
    return '';
  }

  const searchingPart = 'http';
  const httpIndex = url.indexOf(searchingPart);

  if (httpIndex !== 0) {
    return encodeURIComponent(`http://${url}`);
  }

  return encodeURIComponent(url);
};

export const getBestImage = (
  card = {} as WebappCard,
  breakpoint: { desktop: boolean; tablet: boolean; mobile: boolean },
): string | null => {
  const activeBreakpoint = breakpoint.desktop ? 'desktop' : breakpoint.tablet ? 'tablet' : 'mobile';

  return card[activeBreakpoint]?.cropped || card.desktop?.cropped || card.tablet?.cropped || card.mobile?.cropped;
};

export const extractFramesFromCloudinary = async (videoUrl: string): Promise<Frame[]> => {
  const video = document.createElement('video');
  const videoBlob = await fetch(videoUrl).then((r) => r.blob());
  const videoObjectUrl = URL.createObjectURL(videoBlob);
  video.src = videoObjectUrl;
  while ((video.duration === Infinity || isNaN(video.duration)) && video.readyState < 2) {
    await new Promise((r) => setTimeout(r, 1000));
    video.currentTime = 10000000 * Math.random();
  }
  const duration = video.duration;
  const interval = Number((duration / 5).toFixed(1));
  const intervals = [0, interval, interval * 2, interval * 3, interval * 4];
  return await getCloudinaryFrames(videoUrl, intervals);
};

export async function extractFramesFromVideo(videoUrl: string, time?: number): Promise<Frame[]> {
  return new Promise(async (resolve) => {
    const videoBlob = await fetch(videoUrl).then((r) => r.blob());
    const videoObjectUrl = URL.createObjectURL(videoBlob);
    const video = document.createElement('video');
    const seekedResolve = async () => {
      if (seekResolve) seekResolve();
    };
    let seekResolve: any;
    video.addEventListener('seeked', seekedResolve);
    video.src = videoObjectUrl;
    while ((video.duration === Infinity || isNaN(video.duration)) && video.readyState < 2) {
      await new Promise((r) => setTimeout(r, 1000));
      video.currentTime = 10000000 * Math.random();
    }
    const duration = video.duration;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const [w, h] = [video.videoWidth, video.videoHeight];
    canvas.width = w;
    canvas.height = h;
    const frames = [];
    let currentTime = 0;
    if (time !== undefined) {
      currentTime = time;
      video.currentTime = currentTime;
      await new Promise((r) => (seekResolve = r));
      context?.drawImage(video, 0, 0, w, h);
      const base64ImageData = canvas.toDataURL('image/png');
      frames.push({ thumbnail: base64ImageData, time: currentTime });
    } else {
      const interval = duration / 5;
      while (currentTime < duration) {
        video.currentTime = currentTime;
        await new Promise((r) => (seekResolve = r));
        context?.drawImage(video, 0, 0, w, h);
        const base64ImageData = canvas.toDataURL('image/png');
        frames.push({ thumbnail: base64ImageData, time: currentTime });
        currentTime += interval;
      }
    }
    resolve(frames);
    video.removeEventListener('seeked', seekedResolve);
  });
}
export function getVideoDimensionsOf(url: string) {
  return new Promise((resolve) => {
    const video = document.createElement('video');

    video.addEventListener(
      'loadedmetadata',
      () => {
        const height: number = video.videoHeight;
        const width: number = video.videoWidth;

        resolve({ height, width });
      },
      false,
    );

    video.src = url;
  });
}

export const asyncGoogleStorageToCloudinary = async (url: string) => {
  let l = url.split('?')[0] || '';

  if (l.includes('https://storage.googleapis.com/')) {
    l = l.replace('https://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/image/upload/v1/');
  }
  if (l.includes('http://storage.googleapis.com/')) {
    l = l.replace('http://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/image/upload/v1/');
  }
  await fetch(l);
  return l;
};

export const googleStorageToCloudinary = (url: string) => {
  let l = url.split('?')[0] || '';

  if (l.includes('https://storage.googleapis.com/')) {
    l = l.replace('https://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/image/upload/v1/');
  }
  if (l.includes('http://storage.googleapis.com/')) {
    l = l.replace('http://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/image/upload/v1/');
  }
  fetch(l);
  return l;
};

export const getConstrainedThumbnail = async (cloudinaryUrl: string) => {
  const [first, second] = cloudinaryUrl.split('/upload/');
  // add a check to see if second is defined
  const transformed = `${first}/upload/w_300,h_400/v1/${second}`;
  await fetch(transformed);
  return transformed;
};

export function getSyncUpdatedVideoUrl(
  downloadUrl: string | undefined,
  attributesFilterUrl: string | undefined,
  videoPosWidth?: number,
  trackEvent?: IEventTracker,
  fill?: boolean,
) {
  if (!downloadUrl) {
    return '';
  }

  if (attributesFilterUrl) {
    try {
      const filterId = googleStorageToCloudinary(attributesFilterUrl);
      const url = CloudinaryURL(downloadUrl, getFilterPublicId(filterId), 400, fill);
      trackEvent?.(EventNames.Overlay_Filter_Id_Generated);
      return url;
    } catch (error) {
      trackEvent?.(EventNames.Overlay_Filter_Id_Generation_Fail);
      return downloadUrl;
    }
  }

  try {
    const url = getVideoURLWithAspectRatio(downloadUrl, videoPosWidth || 400, fill);
    trackEvent?.(EventNames.Video_With_Overlay_Generated);
    return url;
  } catch (error) {
    trackEvent?.(EventNames.Video_With_Overlay_Generation_Fail);
    return downloadUrl;
  }
}

export async function getUpdatedVideoUrl(
  downloadUrl: string | undefined,
  attributesFilterUrl: string | undefined,
  videoPosWidth?: number,
  trackEvent?: IEventTracker,
  fill?: boolean,
) {
  if (!downloadUrl) {
    return '';
  }

  if (attributesFilterUrl) {
    try {
      const filterId = await asyncGoogleStorageToCloudinary(attributesFilterUrl);
      const url = CloudinaryURL(downloadUrl, getFilterPublicId(filterId), 400, fill);
      trackEvent?.(EventNames.Overlay_Filter_Id_Generated);
      return url;
    } catch (error) {
      trackEvent?.(EventNames.Overlay_Filter_Id_Generation_Fail);
      return downloadUrl;
    }
  }

  try {
    const url = getVideoURLWithAspectRatio(downloadUrl, videoPosWidth || 400, fill);
    trackEvent?.(EventNames.Video_With_Overlay_Generated);
    return url;
  } catch (error) {
    trackEvent?.(EventNames.Video_With_Overlay_Generation_Fail);
    return downloadUrl;
  }
}

export function formatPhoneNumber(phoneNumber: string) {
  return phoneNumber
    .replace(/\D+/g, '')
    .replace(/(\d{3})(\d{3})(\d{1,4})/, '($1) $2-$3')
    .substr(0, 14);
}

export const setPropertyToCloudinaryUrl = (url: string, properties: string) => {
  const [first, second] = url.split('/v1/');

  return `${first}/${properties}/v1/${second}`;
};

// https://stackoverflow.com/a/38935990
export const urlToFile = async (url: string, filename: string, mimeType: string) => {
  return fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }));
};

/**
 * https://cloudinary.com/documentation/layers#image_overlays
 *
 * @example
 * Input: "https://res.cloudinary.com/socialvenu/image/upload/v1/sv-dev-stories/stories/d58e8d4c-8ef2-42b7-8a30-a61269e7e849.jpeg"
 * Output: "sv-dev-stories:stories:d58e8d4c-8ef2-42b7-8a30-a61269e7e849"
 */
export const getCloudinaryLocalPath = (cloudinaryUrl: string) => {
  const [_left, right] = cloudinaryUrl.split('v1/');
  const path = right.split('.')[0];
  return (
    path
      .split('')
      // replace all "/" to ":" (can't use .replaceAll due to compatibility)
      .map((char) => (char === '/' ? ':' : char))
      .join('')
  );
};

export const getTextWidth = (text: string, font: string) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }
  return 0;
};

export const isQrCodeQueryParamSet = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('qrcode') === 'true';
};

export const getAppLinkSource = ({ magicLink }: { magicLink?: boolean }) => {
  if (magicLink) {
    return UserSource.Sms;
  }

  return isQrCodeQueryParamSet() ? UserSource.QrCode : UserSource.Url;
};

export const getVideoAspectRatio = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    const videoURL = URL.createObjectURL(file);
    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = function () {
      URL.revokeObjectURL(videoURL);
      const aspectRatio = video.videoWidth / video.videoHeight;
      resolve(aspectRatio);
    };
    video.onerror = function () {
      reject(new Error('There was an error loading the video.'));
    };
    video.src = videoURL;
  });
};
export const isVideoARCloseToTargetAR = (
  aspectRatio: number,
  epsilon: number = 0.1,
  targetAspectRatio = 9 / 16,
): boolean => {
  return Math.abs(aspectRatio - targetAspectRatio) < epsilon;
};

export const getVideoDuration = async (file: File | string): Promise<number> => {
  const video = document.createElement('video');
  if (typeof file === 'string') {
    const fileBlob = await fetch(file).then((r) => r.blob());
    return getBlobDuration(fileBlob);
  }

  video.src = URL.createObjectURL(file);
  return new Promise((resolve) => {
    video.onerror = () => {
      resolve(0);
    };
    video.onloadedmetadata = () => {
      resolve(video.duration);
    };
  });
};

export const getImageMetadata = async (url: string) => {
  const img = new Image();
  img.src = url;
  await img.decode();

  return img;
};

export const getImageDimensionsToFit = (img: HTMLImageElement, maxWidth: number, maxHeight: number) => {
  let logoW = img.naturalWidth;
  let logoH = img.naturalHeight;

  if (logoW > logoH) {
    logoW = logoW > maxWidth ? maxWidth : logoW;
    logoH = Math.floor(logoW / (img.width / img.height));
    logoH = logoH > maxHeight ? maxHeight : logoH;
    logoW = logoH * (img.width / img.height);
  } else {
    logoH = maxHeight;
    logoW = logoH * (img.width / img.height);
  }

  return {
    width: Math.floor(logoW),
    height: Math.floor(logoH),
  };
};

export const getIsNullReward = (type: CampaignTypes | undefined) => {
  return type ? [CampaignTypes.NO_REWARD, CampaignTypes.MONTHLY_GIVEAWAY].includes(type) : false;
};

