// TODO: it's a rough approximation of what we really need, feel free to change everything
import { VenueAPIModel, VenueProperties } from '../api/models/venue';
import { StoriesApiModel } from '../api/models/stories';
import { UserModel } from '../api/models/auth';
import { CategoryApiModel } from '../api/models/category';
import { LeaderBoardApiModel } from '../api/models/leaderboard';
import { Frame } from '../common/components/UploadedVideoPreview/SelectThumbnailModal/SelectThumbnailModal';
import { CreateStoryRequest } from './slices/upload';

export enum UploadProcessStep {
  AddCellPhone,
  AddHandle,
  AddPassword,
  AddEmail,
  UploadVideo,
  PrepareVideo,
  ConversionDone,
  VideoUploading,
  Uploaded,
}

export interface UploadProcessDTO {
  currentStep: UploadProcessStep;
  cellPhone: string;
  handle: string;
  password: string;
  email: string;
  uploadingProgress: number;
  supportedMimeType: string;
  fileSelected: boolean;
  thumbnailFrameurl: Frame | null;
  pendingUploadedStory: CreateStoryRequest | null;
  sendSMSNotification?: boolean;
  uploadingIterations: UploadIteration[];
  showRecord: boolean;
  isUploadError: boolean;
  isResumingUpload: boolean;
  isUploadInprogress: boolean;
}
export interface UploadIteration {
  timeStamp: number;
  loaded: number;
  total: number;
}

export interface QuestionsApiModel {
  userId: string;
  question: string;
  answer: string;
  createdAt: string;
}

export interface UIDTO {
  sidebarIsOpen?: boolean;
  autoplayWelcomeVideo: boolean;
  signUpFlowActiveTabIndex: number;
  hideCrispChat: boolean;
  questions: {
    isLoading: boolean;
    items: QuestionsApiModel[];
  };
}

export interface PaginationModel<T> {
  items: T[];
  isLoading: boolean;
  totalPages: number;
  totalItems: number;
  page: number;
  size: number;
}

export interface TagsSchema {
  id: string;
  name: string;
}

export interface StoriesDTO {
  stories: PaginationModel<StoriesApiModel>;
  currentStory: StoriesApiModel | null;
  shareStory: StoriesApiModel | null;
  shareStoryLoading: boolean;
  shareStoryRefetching: boolean;
  returnUrl: string | null;
  newStoriesCount: number;
  mainCategory: null | CategoryApiModel;
  filterPublicId: string;
  tags: PaginationModel<TagsSchema>;
  selectedTags: string[] | null;
}

export interface VenueDTO extends VenueAPIModel {
  isLoading: boolean;
  error: boolean;
  lastUpdated: string;
}

export interface VenueAttributesDTO {
  isLoading: boolean;
  error: boolean;
  lastUpdated: string;
  properties: Partial<VenueProperties>;
}

export interface UserModelDTO extends UserModel {
  categoryId?: string;
  isLoading: boolean;
  error?: boolean;
  svuid?: string;
  svTl?: string;
  latestRegisteredToken: string;
  registerErrorType?: RegisterErrorType;
}
export interface UserEmailQueryParams {
  name?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  categoryId?: string;
  returnUrl?: string;
}

export interface LeaderBoardDTO {
  items: LeaderBoardApiModel[];
  campaignUser: LeaderBoardApiModel;
  isLoading: boolean;
}

export interface MagicLinkRequestQueryParams {
  phoneNumber: string;
  accountId: string;
  details?: {
    name: string;
    email: string;
  };
}
export interface VerificationCodeQueryParams {
  phoneNumber: string;
  accountId: string;
  captchaResponse?: string | null;
  details?: {
    firstName?: string;
    lastName?: string;
    email: string | null;
  };
}

export interface MergeUserQueryParams {
  id: string;
  userToMerge: string;
  accessToken: string;
}

export interface SocialVerce {
  id: string;
  accountId: string;
  name: string;
  viewMode: string;
  showTitle: boolean;
  showUserInfo: boolean;
  title: string;
  keywordFilterEnabled: true;
  details: {
    withGlow: boolean;
    glowColor: string;
    captionEnabled: boolean;
    supportingText: string;
    numberOfGridRows: number;
    showVideoCounter: boolean;
    withHoverPreview: boolean;
    showSupportingText: boolean;
    numberOfPerspectiveCards: number;
  };
  gbpSyncEnabled: boolean;
}

export type RegisterErrorType = 'captchaError' | 'userAlreadyExists' | '';
