import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QuestionsApiModel, UIDTO } from '../storeModels';
import { httpClient } from '../../services/httpClient/httpClient';
import { AuthEndpoints } from '../../api/endpoints';

export const UIInitialState: UIDTO = {
  sidebarIsOpen: undefined,
  autoplayWelcomeVideo: false,
  signUpFlowActiveTabIndex: 0,
  hideCrispChat: true,
  questions: {
    isLoading: false,
    items: [],
  },
};

export const GetUserQuestions = createAsyncThunk('ui/GetUserQuestions', async (id: string, { rejectWithValue }) => {
  try {
    return await httpClient.get<null, QuestionsApiModel[]>({
      url: AuthEndpoints.GetUserQuestions.replace(':userId', id),
      requiresToken: true,
    });
  } catch (error) {
    // @ts-ignore
    return rejectWithValue(error.response.data.status);
  }
});

export const PostUserQuestions = createAsyncThunk(
  'ui/PostUserQuestions',
  async (
    options: {
      question: string;
      answer: string;
      id: string;
    },
    { rejectWithValue },
  ) => {
    try {
      return await httpClient.post<{ question: string; answer: string }, QuestionsApiModel>({
        url: AuthEndpoints.GetUserQuestions.replace(':userId', options.id),
        payload: { question: options.question, answer: options.answer },
        requiresToken: true,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.status);
    }
  },
);

const uiSlice = createSlice({
  name: 'ui',
  initialState: UIInitialState,
  reducers: {
    changeSidebarState(state, action: PayloadAction<boolean>) {
      state.sidebarIsOpen = action.payload;
    },
    setAutoplayWelcomeVideo(state, action: PayloadAction<boolean>) {
      state.autoplayWelcomeVideo = action.payload;
    },
    setSignUpFlowActiveTabIndex(state, action: PayloadAction<number>) {
      state.signUpFlowActiveTabIndex = action.payload;
    },
    setHideCrispChat(state, action: PayloadAction<boolean>) {
      state.hideCrispChat = action.payload;
    },
    reset: () => UIInitialState,
  },
  extraReducers: (reducerBuilder) => {
    reducerBuilder.addCase(GetUserQuestions.pending, (state) => {
      state.questions.isLoading = true;
    });
    reducerBuilder.addCase(GetUserQuestions.fulfilled, (state, { payload }) => {
      state.questions.isLoading = false;
      state.questions.items = payload;
    });
    reducerBuilder.addCase(GetUserQuestions.rejected, (state) => {
      state.questions.isLoading = false;
    });
    // reducerBuilder.addCase(PostUserQuestions.pending, (state) => {
    //   state.questions.isLoading = true;
    // });
    reducerBuilder.addCase(PostUserQuestions.fulfilled, (state, { payload }) => {
      state.questions.isLoading = false;
      state.questions.items = [...state.questions.items, payload];
    });
  },
});

export const { changeSidebarState, setSignUpFlowActiveTabIndex, setAutoplayWelcomeVideo, reset, setHideCrispChat } =
  uiSlice.actions;
export default uiSlice.reducer;
